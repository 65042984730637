





















































































































import {Component, Vue} from 'vue-property-decorator'

@Component({})
export default class FitnessPlan extends Vue {
  private date = ''
  private percentage = 0

  private activated() {
    const nowDate = new Date()
    const month = (nowDate.getMonth() + 1).toString().padStart(2, '0')
    const date = nowDate.getDate().toString().padStart(2, '0')
    this.date = `${nowDate.getFullYear()}-${month}-${date}`
  }

  private deactivated() {

  }
}
